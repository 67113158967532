import React, { useState } from "react";

const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle login logic here
    // If login is successful, set the isAuthenticated item in local storage
    if (
      email === process.env.REACT_APP_EMAIL &&
      password === process.env.REACT_APP_PASSWORD
    ) {
      localStorage.setItem("isAuthenticated", true);
      setMessage("Authenticated");
    } else {
      setMessage("Incorrect Credentials");
    }
  };

  return (
    <div className="flex items-center justify-center h-screen">
      <div className="p-5 border w-96 shadow-lg rounded-md bg-white">
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email Address"
            className="p-2 m-2 border rounded w-full"
            required
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            className="p-2 m-2 border rounded w-full"
            required
          />
          <div className="items-center px-4 py-3">
            <button
              id="submitBtn"
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300"
            >
              Login
            </button>
            {message && <p className="mt-4 text-center">{message}</p>}
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
