import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import ConvertFile from "./ConvertFile";

const EmailVerification = () => {
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Add this line
  const location = useLocation();

  useEffect(() => {
    const verifyEmail = async () => {
      const queryParams = new URLSearchParams(location.search);
      const token = queryParams.get("token");
      try {
        setIsLoading(true); // Set loading state to true before the API call
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_API_BASE_URL}/verify-email`,
          { token }
        );
        if (response.data.status === "success") {
          setIsTokenValid(true);
        }
      } catch (error) {
        setIsTokenValid(false);
      } finally {
        setIsLoading(false); // Set loading state to false after the API call
      }
    };

    verifyEmail();
  }, [location]);

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="text-center">
        {isLoading ? (
          <div className="flex justify-center items-center mt-4">
            <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-gray-900"></div>
          </div>
        ) : isTokenValid ? (
          <ConvertFile />
        ) : (
          <p className="text-lg font-semibold">Invalid token</p>
        )}
      </div>
    </div>
  );
};

export default EmailVerification;
