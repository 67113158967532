import React, { useState, useEffect } from "react";
import axios from "axios";

const DeveloperList = () => {
  const [developers, setDevelopers] = useState([]);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_API_BASE_URL}/get-developers`
        );
        setDevelopers(response.data.developers);
      } catch (error) {
        console.error("Error fetching developers:", error);
        // Handle error scenario
      }
      setIsLoading(false);
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_API_BASE_URL}/get-users`
        );
        setUsers(response.data.users);
      } catch (error) {
        console.error("Error fetching users:", error);
        // Handle error scenario
      }
    };

    fetchUsers();
  }, []);

  if (isLoading) {
    return <div>Loading developers...</div>;
  }

  return (
    <>
      <div className="container mx-auto my-8">
        <h1 className="text-center">Developers</h1>
        <table className="min-w-full table-auto">
          <thead className="bg-gray-200">
            <tr>
              <th className="px-4 py-2 text-center">First Name</th>
              <th className="px-4 py-2 text-center">Last Name</th>
              <th className="px-4 py-2 text-center">Email</th>
              <th className="px-4 py-2 text-center">Timestamp</th>
            </tr>
          </thead>
          <tbody>
            {developers.map((developer, index) => (
              <tr key={index} className="bg-white border-b">
                <td className="px-4 py-2 text-center">
                  {developer.name.split(" ")[0]}
                </td>
                <td className="px-4 py-2 text-center">
                  {developer.name.split(" ")[1]}
                </td>
                <td className="px-4 py-2 text-center">{developer.email}</td>
                <td className="px-4 py-2 text-center">{developer.timestamp}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="container mx-auto my-8">
        <h1 className="text-center">Users</h1>
        <table className="min-w-full table-auto">
          <thead className="bg-gray-200">
            <tr>
              <th className="px-4 py-2 text-center">Email</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => (
              <tr key={index} className="bg-white border-b">
                <td className="px-4 py-2 text-center">{user.email}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default DeveloperList;
