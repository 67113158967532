import React, { useState } from "react";
import axios from "axios";

const DeveloperForm = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState(""); // State to store the message

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setMessage(""); // Clear previous messages
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_API_BASE_URL}/save-developer`,
        formData
      );
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_API_BASE_URL}/send-email`,
        formData
      );
      if (response.data.status === "success") {
        setMessage("Email sent successfully!");
      } else {
        setMessage("Failed to send email.");
      }
    } catch (error) {
      setMessage("Error sending email.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-center h-screen">
      <div className="p-5 border w-96 shadow-lg rounded-md bg-white">
        {isLoading ? (
          <div className="flex justify-center items-center">
            <div className="animate-spin rounded-full h-12 w-12 border-b-4 border-gray-900"></div>
          </div>
        ) : (
          <div className="text-center">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Developer Contact Form
            </h3>
            {message && (
              <div className="text-center text-sm my-2 text-red-600">
                {message}
              </div>
            )}
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
                placeholder="First Name"
                className="p-2 m-2 border rounded w-full"
                required
              />
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
                placeholder="Last Name"
                className="p-2 m-2 border rounded w-full"
                required
              />
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="Email Address"
                className="p-2 m-2 border rounded w-full"
                required
              />
              <div className="items-center px-4 py-3">
                <button
                  id="submitBtn"
                  type="submit"
                  className="px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300"
                >
                  Send Email
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default DeveloperForm;
