import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import EmailForm from "./components/EmailForm";
import DeveloperForm from "./components/DeveloperForm";
import DeveloperList from "./components/DeveloperList";
import EmailVerification from "./components/EmailVerification";
import PrivateRoute from "./components/PrivateRoute";
import LoginForm from "./components/LoginForm";

function App() {
  return (
    <Router>
      <div className="flex justify-between p-4">
        <Link to="/" className="p-2  rounded mr-2">
          Media Conversion
        </Link>
        <div>
          <Link
            to="/developers"
            className="p-2 bg-blue-500 text-white rounded mr-2"
          >
            Developers
          </Link>
          <Link to="/admin" className="p-2 bg-green-500 text-white rounded">
            Admin
          </Link>
        </div>
      </div>

      <Routes>
        <Route path="/" element={<EmailForm />} />
        <Route path="/login" element={<LoginForm />} />
        <Route path="/developers" element={<DeveloperForm />} />
        <Route
          path="/admin"
          element={
            <PrivateRoute>
              <DeveloperList />
            </PrivateRoute>
          }
        />
        <Route path="/verify" element={<EmailVerification />} />
        {/* Add more routes here if needed */}
      </Routes>
    </Router>
  );
}

export default App;
